<template>
    <div class="is-flex is-justify-content-center is-align-content-center is-align-items-center">
        <loading iconBefore="check-circle" :showDots="showDots" title="You have successfully purchased HybRooms Business"
            subtitle="You will be redirected to Microsoft Teams Store to open HybRooms" :subtitleDelay="delay">
            <template v-slot:before>
                <b-icon icon="check-circle" class="is-size-1 mb-4" type="is-success"></b-icon>
            </template>
            <template v-slot:after>
                <div v-if="timeoutCompleted" class="is-flex is-justify-content-center is-align-items-center">
                    <p>Click <a :href="link">{{ $t("toHere") }}</a> <b-icon icon="external-link-alt"></b-icon> if you are
                        not
                        being
                        redirected</p>
                </div>
            </template>
        </loading>

    </div>
</template>

<script>
import Loading from "@/components/Loading.vue";

export default {
    components: {
        loading: Loading,
    },
    data() {
        return {
            delay: 2.5,
            timeoutCompleted: false,
            showDots: false,
            link: "https://appsource.microsoft.com/de-de/product/office/WA200002592?tab=Overview"
        }
    },
    mounted() {
        setTimeout(() => {
            this.showDots = true;
            setTimeout(() => {
                this.timeoutCompleted = true;
                this.openHybRoomsInStore();
            }, this.delay * 1000)
        }, this.delay * 1000);
    },
    methods: {
        openHybRoomsInStore() {
            window.open(this.link, "_self");
        }
    }
}
</script>