<template>
    <div class="modal-card">
        <section class="modal-card-body">
            <b-field>
                <h1 class="title mb-5">{{ title ? title : $t('roomSettings') }}</h1>
            </b-field>

            <form v-on:submit.prevent>
                <b-field :label="room.adHoc ? $t('subjectOfCall') : $t('roomName')" label-position="on-border">
                    <b-input v-model="room.name" :minLength="$CONSTANTS.MIN_ROOM_NAME_LENGTH"
                        :maxlength="$CONSTANTS.MAX_ROOM_NAME_LENGTH"
                        :has-counter="room.name.length >= $CONSTANTS.MAX_ROOM_NAME_LENGTH * 0.85"></b-input>
                </b-field>
                <b-collapse v-if="room.adHoc" :open="!room.adHoc" position="is-top" aria-id="contentIdForA11y4">
                    <template #trigger="props">
                        <a aria-controls="contentIdForA11y4" :aria-expanded="props.open">
                            {{ $t("advancedSettings") }}
                            <span class="icon-text">
                                <span class="icon">
                                    <i>
                                        <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
                                    </i>
                                </span>
                            </span>
                        </a>
                    </template>
                    <room-settings class="mt-4" :roomData="roomData" />
                </b-collapse>
                <room-settings v-else :roomData="roomData" />

                <div class="is-flex is-justify-content-space-between mt-4">
                    <b-button :label="$t('interaction.cancel')" @click="cancel" />
                    <b-button :label="$t('interaction.OK')" native-type="submit" type="is-primary" v-on:click="submit"
                        :disabled="!roomNameOk" />
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import RoomSettings from '@/components/RoomSettings.vue';

export default {
    components: {
        "room-settings": RoomSettings
    },
    props: {
        roomData: {
            type: Object,
            required: true
        },
        roomIndex: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            room: this.roomData
        }
    },
    computed: {
        roomNameOk() {
            return this.room.name.length >= this.$CONSTANTS.MIN_ROOM_NAME_LENGTH && this.room.name.length <= this.$CONSTANTS.MAX_ROOM_NAME_LENGTH;
        },
        isEnterprisePlan() {
            return this.$store.state.subscriptionPlan == "enterprise";
        }
    },
    methods: {
        submit() {
            if (this.room.name) {
                this.$emit('submitNewRoom', this.room);
                this.$emit('close');
            }
        },
        cancel() {
            this.$emit('cancel');
            this.$emit('close');
        }
    }
}
</script>
