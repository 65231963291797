<template>
    <div class="avatarContainer">
        <div class="layer" :style="avatarSize">
            <b-image v-on:error="imageError" :src="src" ratio="1by1" rounded
                :style="isPlaceholder ? 'visibility: hidden;' : ''"></b-image>
        </div>
        <div class="layer is-flex is-justify-content-center is-align-items-center has-background-dark"
            :style="initialsContainerStyle">
            <p class="title has-text-white" :style="avatarInitialsStyle">{{ initials }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            default: function () { return this.$CONSTANTS.AVATAR_SIZE_PX }
        },
        src: {
            type: String,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            isPlaceholder: false
        }
    },
    computed: {
        avatarSize() {
            return `width: ${this.size}px; height: ${this.size}px;`;
        },
        avatarInitialsStyle() {
            const fontSize = Math.round(this.size * 0.45);
            return `margin-top: 3px; font-size: ${fontSize}px;`;
        },
        initialsContainerStyle() {
            const visibility = this.isPlaceholder ? "" : "visibility: hidden;";
            const borderRadius = "border-radius: 100%;";
            return borderRadius + " " + visibility + " " + this.avatarSize;
        },
        initials() {
            if (this.placeholder)
                return this.placeholder;
            if (this.user.givenName && this.user.surname)
                return (this.user.givenName[0] + this.user.surname[0]).toUpperCase();
            const words = this.user.displayName.trim().split(/\s+/);
            if (words.length === 0) {
                return "";
            }
            const firstInitial = words[0][0];
            const lastInitial = words[words.length - 1][0];
            return (firstInitial + lastInitial).toUpperCase();
        }
    },
    methods: {
        imageError() {
            this.isPlaceholder = true;
        }
    }
}
</script>

<style lang="css" scoped>
.avatarContainer {
    display: grid;
}

.layer {
    grid-column: 1;
    grid-row: 1;
}
</style>