import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import OfficeSpaceView from '../views/OfficeSpaceView.vue'
import AboutView from '../views/AboutView.vue'
import PurchaseCompletedView from '../views/PurchaseCompletedView.vue'
import FinalMessageView from '../views/FinalMessageView.vue'

import store from '../store/index';

import { hybRoomsConnector } from "../lib/connector";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  //{ path: '/', redirect: '/office' },
  {
    path: '/',
    name: 'landingPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LandingPage.vue')
  },
  {
    path: '/admincenter',
    name: 'admincenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminCenterView.vue'),
  },
  {
    path: '/configure/:client',
    name: 'configure',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Configure.vue'),
  },
  {
    path: '/checkout/:client/:plan/:period',
    name: 'checkout',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Checkout.vue'),
  },
  {
    path: '/consent/:status',
    name: 'consent',
    component: AboutView,
    props: true
  },
  {
    path: '/purchasecompleted',
    name: 'purchasecompleted',
    component: PurchaseCompletedView,
    props: true
  },
  {
    path: '/init',
    name: 'init',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InitView.vue')
  },
  {
    path: '/office/:tid?/:officeSpaceId?',
    name: 'officeSpace',
    component: OfficeSpaceView,
    beforeEnter: (to, _from, next) => {
      store.state.viewTenantId = to.params.tid || "";
      store.state.viewedOfficeSpaceId = to.params.officeSpaceId || "";
      if (store.state.viewTenantId) {
        store.state.fetchingOfficeSpaces = true;
        hybRoomsConnector.listOfficeSpaces(store.state.viewTenantId)
          .then((response: { data: { officeSpaces: never[]; companyName: null; }; }) => {
            store.state.officeSpaces = response.data.officeSpaces;
            store.state.companyName = response.data.companyName;
            next();
          })
          .catch(() => next("/error"))
          .finally(() => {
            store.state.fetchingOfficeSpaces = false;
          });
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PricingViewFreemium.vue')
  },
  {
    path: '/datasubjectaccessrequest',
    name: 'datasubjectaccessrequest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DataSubjectAccessRequestFormView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactFormView.vue')
  },
  {
    path: '/finalize',
    name: 'finalize',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FinalMessageView.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AnalyticsView.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
