<template>
    <div class="about">
        <section class="hero is-grey is-fullheight has-background">
            <img class="hero-background is-transparent"
                src="https://hybrooms-public.s3.eu-central-1.amazonaws.com/coworking_1920x1280.jpg" />
            <div class="hero-body is-flex is-justify-content-center is-align-items-center container">
                <div class="has-text-centered">
                    <p v-html="title" class="title is-flex is-justify-content-center mb-6"></p>
                    <p class="subtitle is-flex is-justify-content-center">{{ subSentence }}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            required: false
        }
    },
    computed: {
        isAboutView() {
            return this.$router.currentRoute.name == "about";
        },
        approved() {
            return !this.isAboutView && this.status == "approved";
        },
        addedZoomApp() {
            return !this.isAboutView && this.status == "addedZoomApp";
        },
        title() {
            return this.$t("sentences.hybroomsWelcomeSentence");
        },
        subSentence() {
            if (this.isAboutView)
                return this.$t("sentences.stayTuned");
            else if (this.approved)
                return this.$t("sentences.hybroomsApprovedSentence");
            else if (this.addedZoomApp)
                return this.$t("sentences.addedZoomAppSentence");
            else
                return this.$t("sentences.continueWithoutAdminConsentSentence");
        }
    }
}
</script>

<!--script>
export default {
    mounted() {
        const html = document.documentElement;
        const canvas = document.getElementById("hero-lightpass");
        const context = canvas.getContext("2d");

        const frameCount = 148;
        const currentFrame = index => (
            `https://www.apple.com/105/media/us/airpods-pro/2019/1299e2f5_9206_4470_b28e_08307a42f19b/anim/sequence/large/01-hero-lightpass/${index.toString().padStart(4, '0')}.jpg`
        )

        const preloadImages = () => {
            for (let i = 1; i < frameCount; i++) {
                const img = new Image();
                img.src = currentFrame(i);
            }
        };

        const img = new Image()
        img.src = currentFrame(1);
        canvas.width = 1158;
        canvas.height = 770;
        img.onload = function () {
            context.drawImage(img, 0, 0);
        }

        const updateImage = index => {
            img.src = currentFrame(index);
            context.drawImage(img, 0, 0);
        }

        window.addEventListener('scroll', () => {
            const scrollTop = html.scrollTop;
            const maxScrollTop = html.scrollHeight - window.innerHeight;
            const scrollFraction = scrollTop / maxScrollTop;
            const frameIndex = Math.min(
                frameCount - 1,
                Math.ceil(scrollFraction * frameCount)
            );

            requestAnimationFrame(() => updateImage(frameIndex + 1))
        });

        preloadImages()
    },
}
</script-->

<!--style lang="sass" scoped>
canvas
  position: fixed
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  max-width: 100vw
  max-height: 100vh
</style-->

<style lang="sass" scoped>
.hero
  &-body
    z-index: 10
  &.has-background
    position: relative
    overflow: hidden
  &-background
    position: absolute
    object-fit: cover
    object-position: center center
    width: 100%
    height: 100%
    &.is-transparent
      opacity: 0.3
</style>
