<template>
    <div class="modal-card">
        <section class="modal-card-body">
            <b-field v-if="title">
                <h5 class="title is-5">{{ title }}</h5>
            </b-field>
            <b-field>
                <div v-html="text" />
            </b-field>
            <div class="is-flex is-justify-content-right">
                <b-field grouped group-multiline>
                    <b-field>
                        <b-button v-if="cancelButtonText" :type="type" outlined v-on:click="cancel">
                            {{ cancelButtonText }}
                        </b-button>
                    </b-field>
                    <b-field>
                        <b-button v-if="confirmButtonText" :icon-right="confirmButtonRightIcon" :type="type"
                            v-on:click="confirm">
                            {{ confirmButtonText }}
                        </b-button>
                    </b-field>
                </b-field>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        confirmButtonText: {
            type: String,
            required: false,
            default: function () { return this.$t("interaction.OK") }
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: function () { return this.$t("interaction.cancel") }
        },
        title: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: "is-primary"
        },
        confirmButtonRightIcon: {
            type: String,
            required: false,
            default: undefined
        }
    },
    methods: {
        cancel() {
            this.$emit('confirm', false);
            this.$emit('close');
        },
        confirm() {
            this.$emit('confirm', true);
            this.$emit('close');
        }
    }
};
</script>

<style>
.field.is-grouped.is-grouped-multiline {
    row-gap: 0.75rem;
    padding-bottom: 0.75rem;
}
</style>