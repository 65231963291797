export function getToday() {
    return new Date();
}

export function getYesterday() {
    const today = getToday();
    return new Date(today.setDate(today.getDate() - 1));
}

export function getSystemTimeZone(){
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function timeZoneFloatToUTCString(tz){
    const hours = (tz<0?"":"+") + Math.trunc(tz).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    const minutes = (Math.abs(tz - Math.trunc(tz)) * 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    return 'UTC'+hours+minutes
}

export function getTimezoneSuffixOfDate(date){
    const offset = -date.getTimezoneOffset()
    return timeZoneFloatToUTCString(offset / 60)
}

export function createDateString(date){
    return [date.getFullYear(), (date.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits: 2}), date.getDate()].join('-')
}

export function convertTimezoneDateToUTCMilliseconds(date, tz){
    var str = createDateString(date) + timeZoneFloatToUTCString(tz);
    return new Date(str).getTime();
}

export function convertUTCDateToLocalDate(date){
    return date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
}

export function isDSTActive(date) {
    var jan = new Date(date.getFullYear(), 0, 1);
    var jul = new Date(date.getFullYear(), 6, 1);
    return date.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export function getWeekdayWithMondayZero(date){
    var weekday = date.getDay()
    return weekday > 0 ? weekday - 1: 6
}

export function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

/*export function convertBetweenTimeZones(date, destination){
    const optionsFrom = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit',
        timeZone: from
    }
}*/

/*export function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
}*/

/*Date.prototype.toJSON = function(){
    const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60000;
    this.setHours(hoursDiff);
    return this.toISOString();
};*/