<template>
    <div class="modal-card">
        <section class="modal-card-body">
            <div class="is-flex is-justify-content-space-between">
                <h5 class="title is-5">{{ event.data.subject }}</h5>
                <div class="is-flex is-justify-content-right">
                    <button type="button" class="is-rounded delete" v-on:click="close"></button>
                </div>
            </div>
            <b-field>
                <h5 class="subtitle is-6">{{ formattedDate }}</h5>
            </b-field>
            <b-field v-if="event.data.isOnline">
                <b-button class="is-primary" v-on:click="joinMeeting">Join</b-button>
            </b-field>
            <hr>
            <b-field>
                <span class="icon-text">
                    <span class="icon">
                        <i>
                            <b-icon icon="map-marker-alt" />
                        </i>
                    </span>
                </span>
                <span>
                    {{ event.data.location }}
                </span>
            </b-field>
            <hr>
            <b-field v-if="event.data.isOnline">
                <span class="icon-text">
                    <span class="icon">
                        <i>
                            <b-icon icon="link" />
                        </i>
                    </span>
                </span>
                <span style="white-space: nowrap;">
                    <a v-on:click="joinMeeting">{{ event.data.url.substring(0, 42)
                    }}...</a>
                </span>
                <b-field>
                    <span class="icon-text">
                        <span class="icon">
                            <i>
                                <b-icon icon="clone" custom-class="far" />
                            </i>
                        </span>
                    </span>
                </b-field>
            </b-field>
            <hr>
            <b-field v-if="event.data.isHTML">
                <span v-html="event.data.body" />
            </b-field>
            <b-field v-else>
                <span>{{ event.data.body }}</span>
            </b-field>
        </section>
    </div>
</template>

<script>
const dateFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
};

export default {
    props: {
        event: {
            type: Object,
            required: true
        },
    },

    computed: {
        formattedDate() {
            try {
                const endDateFormatted = this.endsSameDay ? this.event.end.toLocaleTimeString(this.$i18n.locale, { hour: 'numeric', minute: 'numeric', second: undefined }) : this.event.end.toLocaleDateString(this.$i18n.locale, dateFormatOptions);
                return this.event.start.toLocaleDateString(this.$i18n.locale, dateFormatOptions) + ' - ' + endDateFormatted;
            } catch {
                return '';
            }
        },
        endsSameDay() {
            return (this.event.start.getDate() == this.event.end.getDate()) && (this.event.start.getMonth() == this.event.end.getMonth());
        }
    },

    methods: {
        joinMeeting() {
            this.$emit("joinMeetingFromEventModal", this.event.data.url);
            this.close();
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style>
#closeIcon:hover {
    color: black !important;
}

.horizontal-aligned-label .field-label {
    flex-grow: 2.5;
    margin-right: 1.75em;
}

/*.horizontal-aligned-label .field-label .label {
    flex-grow: 2;
    padding-top: 0.5em;
}*/
</style>
