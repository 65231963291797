<template>
    <div>
        <b-icon v-if="copied" pack="fas" :icon="success ? 'check' : 'times'" :type="successType" />
        <b-tooltip v-else :label="tooltip" :type="tooltipType">
            <b-icon :class="`is-clickable ${iconClass}`" pack="fas" icon="link" v-on:click.native="copy" />
        </b-tooltip>
    </div>
</template>

<script>
import { copyToClipboard } from '@/lib/clipboard';

export default {
    data() {
        return {
            success: false,
            copied: false
        }
    },
    props: {
        tooltip: {
            type: String,
            required: true
        },
        successMessage: {
            type: String,
            required: true
        },
        failureMessage: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        copyNotificationDuration: {
            type: Number,
            default: 1500
        },
        iconClass: {
            type: String,
            default: "custom-hover-primary"
        },
        tooltipType: {
            type: String,
            default: "is-primary"
        }
    },
    computed: {
        notificationMessage() {
            return this.success ? this.successMessage : this.failureMessage;
        },
        successType() {
            return this.success ? "is-success" : "is-danger";
        }
    },
    methods: {
        copy() {
            copyToClipboard(this.text).then(success => {
                this.success = success;
            }).catch(() => {
                this.success = false;
            }).finally(() => this.notify());
        },

        notify() {
            this.copied = true;
            this.$buefy.toast.open({
                duration: this.copyNotificationDuration,
                message: this.notificationMessage,
                type: this.successType,
                pauseOnHover: true
            })
            setTimeout(() => {
                this.copied = false;
            }, this.copyNotificationDuration);
        }
    }
};
</script>
