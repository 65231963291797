<template>
    <b-breadcrumb align="is-left" :class="!isMobile ? 'is-size-6' : 'is-size-7'">
        <b-breadcrumb-item v-for="l of locationHierarchy" :key="l.link" tag='router-link' :to="l.link">
            <span class="icon-text">
                <span v-if="l.isDefaultOfficeSpace" class="icon">
                    <i>
                        <b-icon pack="fas" icon="home" />
                    </i>
                </span>
                <span v-if="shortenPath && l.shortIcon" class="icon">
                    <i>
                        <b-icon pack="fas" :icon="l.shortIcon" />
                    </i>
                </span>
                <span v-else>{{ getShortenedName(l.name) }}</span>
            </span>
        </b-breadcrumb-item>
    </b-breadcrumb>
</template>

<script>
export default {
    props: {
        shortenPath: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        isMobile() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.tablet;
        },

        officeSpaces() {
            return this.$store.state.officeSpaces;
        },

        viewTenantId() {
            return this.$store.state.viewTenantId;
        },

        viewedOfficeSpaceId() {
            return this.$store.state.viewedOfficeSpaceId;
        },

        currentOfficeSpace() {
            return this.officeSpaces.find(o => o.id == this.viewedOfficeSpaceId && o.tid == this.viewTenantId);
        },

        defaultOfficeSpace() {
            return this.$store.state.defaultOfficeSpace;
        },

        isDefaultOfficeSpace() {
            if (this.defaultOfficeSpace && this.currentOfficeSpace && this.currentOfficeSpace.tid == this.defaultOfficeSpace.tid && this.currentOfficeSpace.id == this.defaultOfficeSpace.id)
                return true;
            return false;
        },

        companyName() {
            if (this.$store.state.demoMode.active && this.$store.state.demoMode.companyNameOverride.active)
                return this.$store.state.demoMode.companyNameOverride.value;
            return this.$store.state.companyName || "HybRooms";
        },

        locationHierarchy() {
            let hierarchy = [{
                name: this.companyName,
                link: encodeURI(`/office/${this.viewTenantId}`),
                shortIcon: "building"
            }];
            if (this.currentOfficeSpace)
                hierarchy.push({
                    name: this.currentOfficeSpace.name,
                    link: encodeURI(`/office/${this.viewTenantId}/${this.currentOfficeSpace.id}`),
                    isDefaultOfficeSpace: this.isDefaultOfficeSpace
                })
            return hierarchy;
        }
    },

    methods: {
        getShortenedName(name, maxLength = 25) {
            if (!this.shortenPath)
                return name;
            if (this.isDefaultOfficeSpace)
                maxLength -= 3;
            const placeholder = "...";
            const partLength = Math.floor((maxLength - placeholder.length) / 2);
            if (this.isMobile && name.length > maxLength + placeholder.length) {
                return name.slice(0, partLength) + placeholder + name.slice(name.length - partLength);
            }
            return name;
        }
    }
}
</script>