import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

enum BREAKPOINTS {
  mobile = 0,
  tablet = 769,
  desktop = 1024,
  widescreen = 1216,
  fullhd = 1408
}

const LoadingComponentsRegistry: { [key: number]: { counter: number, component: any } } = {};

export default new Vuex.Store({
  state: {
    os: "",
    hybRoomsClient: "",
    clientType: "",
    darkMode: false,
    systemDarkMode: false,
    loadingComponents: LoadingComponentsRegistry,
    me: null,
    myPhoto: "",
    calendarAllowed: false,
    myEvents: {},
    viewTenantId: "",
    viewedOfficeSpaceId: "",
    companyName: null,
    subscriptionPlan: "",
    defaultOfficeSpace: {},
    officeSpaces: [],
    rooms: [],
    participants: {},
    costs: {},
    lastCosts: {},
    profilePictures: {},
    history: [],
    lastBack: {},
    lastForward: {},
    historyIndex: 1,
    fetchingOfficeSpaces: false,
    COST_PER_HOUR: 50,
    CURRENCY: "€",
    windowWidth: 0,
    windowHeight: 0,
    calendarCellSizeFactor: 1.5,
    landingPageConversationAnimationType: "typewriterWord",
    LOCALES: { 'en-GB': 'English', 'de-DE': 'Deutsch', 'fr-FR': 'Français', 'es-ES': "Español", 'it-IT': 'Italiano', 'tr-TR': 'Türkçe', 'pt-PT': 'Português', 'zh-CN': '中文', 'hi-IN': 'हिन्दी', 'ru-RU': 'Русский' },
    BREAKPOINTS: BREAKPOINTS,
    demoMode: {
      active: false,
      showDummyParticipants: { active: false },
      companyNameOverride: { active: false, value: "HybRooms Company" },
      displayNameOverride: { active: false, value: "Monica Snow" },
      dateTimeOverride: { active: false, value: new Date(2024, 7, 15, 15, 15) },
      costUpdateIntervalOverride: { active: false, value: 1500 }
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
