<template>
    <article class="notification is-primary is-clickable rounded-borders" v-on:click="goToOfficeSpace"
        style="height: 100%;">
        <div class="is-flex is-justify-content-space-between is-align-items-top">
            <b-field grouped>
                <b-field v-if="isDefaultOfficeSpace">
                    <b-icon pack="fas" icon="home" size="is-large" />
                </b-field>
                <p class="subtitle">{{ officeSpaceData.name }}</p>
            </b-field>
            <div>
                <b-dropdown aria-role="list" :position="dropdownPosition" v-on:active-change="setDropdownPosition"
                    :max-height="dropdownMaxHeight" @mouseover.native="preventEnter" @mouseleave.native="allowEnter">
                    <template #trigger>
                        <span ref="ellipsis" class="icon is-clickable custom-hover-grey">
                            <b-icon pack="fas" icon="ellipsis-h" />
                        </span>
                    </template>

                    <!--b-dropdown-item aria-role="listitem" v-on:click="officeSpaceSettings">
                    <b-field grouped class="custom-hover-primary">
                        <b-field>
                            <b-icon pack="fas" icon="cog" size="is-small" />
                        </b-field>
                        <b-field>
                            {{ $t("officeSpaceSettings") }}
                        </b-field>
                    </b-field>
                </b-dropdown-item-->

                    <b-dropdown-item aria-role="listitem" v-on:click="toggleDefaultOfficeSpace">
                        <b-field grouped class="custom-hover-primary">
                            <b-field>
                                <b-icon pack="fas" icon="home" size="is-small" />
                            </b-field>
                            <b-field>
                                {{ !isDefaultOfficeSpace ? $t("makeMyDefaultOfficeSpace") : $t("unsetDefaultOfficeSpace") }}
                            </b-field>
                        </b-field>
                    </b-dropdown-item>

                    <b-dropdown-item separator />

                    <b-dropdown-item aria-role="listitem" v-on:click="deleteOfficeSpace">
                        <b-field grouped class="custom-hover-red">
                            <b-field>
                                <b-icon pack="fas" icon="trash" size="is-small" />
                            </b-field>
                            <b-field>
                                {{ $t("deleteOfficeSpace") }}
                            </b-field>
                        </b-field>
                    </b-dropdown-item>
                </b-dropdown>
                <b-field class="mt-3">
                    <copy-icon iconClass="custom-hover-grey" tooltipType="is-dark" :tooltip="$t('copyOfficeSpaceLink')"
                        :successMessage="$t('copiedLink')" :failureMessage="$t('failedToCopyTheLink')" :text="link"
                        @mouseover.native="preventEnter" @mouseleave.native="allowEnter"></copy-icon>
                </b-field>
            </div>
        </div>
    </article>
</template>

<script>
import { hybRoomsConnector } from '@/lib/connector';
import CopyIcon from "@/components/CopyIcon.vue";

export default {
    props: {
        officeSpaceData: {
            type: Object,
            required: true
        }
    },

    components: {
        "copy-icon": CopyIcon
    },

    computed: {
        isDefaultOfficeSpace() {
            if (this.defaultOfficeSpace && this.officeSpaceData.tid == this.defaultOfficeSpace.tid && this.officeSpaceData.id == this.defaultOfficeSpace.id)
                return true;
            return false;
        },

        defaultOfficeSpace() {
            return this.$store.state.defaultOfficeSpace;
        },

        link() {
            if (this.$store.state.hybRoomsClient == this.$CONSTANTS.MS_TEAMS_CLIENT_NAME)
                return `https://teams.microsoft.com/l/entity/${this.$CONSTANTS.CLIENT_IDS.msteams[process.env.NODE_ENV]}/init?context=%7B%22subEntityId%22%3A%22${this.officeSpaceData.tid}.${this.officeSpaceData.id}%22%7D`;
            return this.$getInitUrl(this.officeSpaceData.tid, this.officeSpaceData.id);
        }
    },

    data() {
        return {
            preventOfficeEnter: false,
            dropdownMaxHeight: 200,
            dropdownPosition: "is-bottom-right",
        }
    },

    methods: {
        goToOfficeSpace() {
            if (!this.preventOfficeEnter)
                this.$emit('goToOfficeSpace', this.officeSpaceData)
        },

        officeSpaceSettings() {
            return;
        },

        toggleDefaultOfficeSpace() {
            if (this.isDefaultOfficeSpace) {
                this.$deleteCookie("defaultOfficeSpace");
                this.$store.state.defaultOfficeSpace = {};
            }
            else {
                this.$setCookie("defaultOfficeSpace", this.officeSpaceData, 365);
                this.$store.state.defaultOfficeSpace = this.officeSpaceData;
            }
        },

        deleteOfficeSpace() {
            const loadingComponentId = this.$showLoading(this.$t("deletingX", { x: this.officeSpaceData.name }));
            setTimeout(() => this.$closeLoading(loadingComponentId), 10 * 1000);
            hybRoomsConnector.deleteOfficeSpace(this.officeSpaceData).then(() => {
                this.$store.state.officeSpaces = this.$store.state.officeSpaces.filter(o => o.tid != this.officeSpaceData.tid || o.id != this.officeSpaceData.id)
                this.$store.state.rooms = this.$store.state.rooms.filter(r => r.tid != this.officeSpaceData.tid || r.officeSpaceId != this.officeSpaceData.id);
                this.notifyOfficeSpaceDeletionResult(true);
            }).catch(() => this.notifyOfficeSpaceDeletionResult(false))
                .finally(() => this.$closeLoading(loadingComponentId));
        },

        notifyOfficeSpaceDeletionResult(success) {
            this.$buefy.toast.open({
                duration: 2500,
                message: success ? this.$t("deletedOfficeSpace") : this.$t("failedToDeleteOfficeSpace"),
                type: success ? "is-success" : "is-danger",
                pauseOnHover: true
            })
        },

        preventEnter() {
            this.preventOfficeEnter = true;
        },

        allowEnter() {
            this.preventOfficeEnter = false;
        },

        setDropdownPosition() {
            const bounds = this.$refs.ellipsis.getBoundingClientRect();
            const horizontal = window.innerWidth - bounds.right < 350 ? "left" : "right";
            const vertical = window.innerHeight - bounds.bottom < this.dropdownMaxHeight + 5 ? "top" : "bottom";
            this.dropdownPosition = `is-${vertical}-${horizontal}`;
        }
    }
}
</script>