<template>
    <div v-if="isMobile" class="bottom-bar is-flex is-justify-content-space-between" :style="style">
        <div class="is-flex is-align-items-center ml-4">
            <b-button style="border: none;" icon-left="arrow-left" :disabled="!backPossible" v-on:click="back"></b-button>
        </div>
        <div class="is-flex is-align-items-center mx-4" style="max-width: (80% - 6rem);">
            <breadcrumb :shortenPath="true" />
        </div>
        <div class="is-flex is-align-items-center mr-4">
            <b-button style="border: none;" icon-left="arrow-right" :disabled="!forwardPossible"
                v-on:click="forward"></b-button>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
    components: {
        breadcrumb: Breadcrumb
    },

    computed: {
        history() {
            return this.$store.state.history;
        },

        historyIndex: {
            get() {
                return this.$store.state.historyIndex;
            },
            set(value) {
                this.$store.state.historyIndex = value;
            }
        },

        isMobile() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.tablet;
        },

        backPossible() {
            return this.historyIndex > 1;
        },

        forwardPossible() {
            return this.historyIndex < this.history.length;
        },

        style() {
            const color = this.$store.state.darkMode ? "#1b1b1b" : "white";
            return `position:fixed; bottom: 0px; width: 100%; max-width: 100%; z-index: 30; height: 3.25rem; background-color: ${color};`
        }
    },

    methods: {
        back() {
            if (this.backPossible) {
                this.$store.state.lastBack = { timestamp: new Date().getTime(), from: this.$router.history.current.path }
                this.$router.go(-1);
            }
        },

        forward() {
            if (this.forwardPossible) {
                this.$store.state.lastForward = { timestamp: new Date().getTime(), from: this.$router.history.current.path }
                this.$router.go(1);
            }
        }
    }
}
</script>