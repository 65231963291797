<template>
    <div :id="randomId">
        <b-dropdown v-model="cardOpen" :position="dropdownPosition" :triggers="[isMobileOrTablet ? 'click' : 'hover']"
            aria-role="menu" :trap-focus="true" v-on:toggle="hoveredUser = undefined" class="person-dropdown">
            <template #trigger>
                <div class="columns">
                    <div class="column is-narrow">
                        <avatar :size="size" :src="image" :user="user" :placeholder="placeholder"
                            :key="JSON.stringify(user) + JSON.stringify(image)" />
                    </div>
                    <div class="column is-flex is-align-items-center is-align-content-center" v-if="withName">
                        <a>{{ displayName }}</a>
                    </div>
                </div>
            </template>

            <b-dropdown-item v-if="isMe && isMobileOrTablet" aria-role="menu-item" :focusable="false" custom paddingless>
                <div class="modal-card">
                    <section class="modal-card-body">
                        <span>{{ displayName }}</span>
                    </section>
                </div>
            </b-dropdown-item>

            <b-dropdown-item v-if="!isMe" aria-role="menu-item" :focusable="false" custom paddingless>
                <div class="modal-card" :style="directConnectIconsCardStyle">
                    <section class="modal-card-body">
                        <div v-if="stackedUsers.length == 0">
                            <span>{{ displayName }}</span>
                            <div class="is-flex is-justify-content-space-between mt-2">
                                <span v-on:click="contactUser('chat')" class="icon is-clickable custom-hover-primary">
                                    <b-icon pack="fas" icon="comment-dots" size="is-medium" />
                                </span>
                                <span v-on:click="contactUser('audio')" class="icon is-clickable custom-hover-primary">
                                    <b-icon pack="fas" icon="phone" size="is-medium" />
                                </span>
                                <span v-on:click="contactUser('video')" class="icon is-clickable custom-hover-primary">
                                    <b-icon pack="fas" icon="video" size="is-medium" />
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-for=" u of stackedUsers" :key="u.tid + u.id"
                                @mouseover="hoveredUser = { tid: u.tid, id: u.id }" @mouseleave="hoveredUser = undefined">
                                <div class="columns is-vcentered">
                                    <div class="column is-6"
                                        :style="hoveredUser?.tid != u.tid || hoveredUser?.id != u.id ? 'opacity: 0.5;' : ''">
                                        {{ u.displayName }}
                                    </div>
                                    <div v-if="hoveredUser?.tid == u.tid && hoveredUser?.id == u.id" class="column">
                                        <div class="column is-flex is-justify-content-space-between">
                                            <span v-on:click="contactUser('chat')"
                                                class="icon is-clickable custom-hover-primary">
                                                <b-icon pack="fas" icon="comment-dots" size="is-medium" />
                                            </span>
                                            <span v-on:click="contactUser('audio')"
                                                class="icon is-clickable custom-hover-primary">
                                                <b-icon pack="fas" icon="phone" size="is-medium" />
                                            </span>
                                            <span v-on:click="contactUser('video')"
                                                class="icon is-clickable custom-hover-primary">
                                                <b-icon pack="fas" icon="video" size="is-medium" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import Vue from "vue";
import Avatar from "@/components/Avatar.vue";
import { hybRoomsConnector } from '@/lib/connector';
import {
    app
} from "@microsoft/teams-js";

export default {
    data() {
        return {
            insideTooltip: false,
            insideAvatar: false,
            cardOpen: false,
            hoveredUser: undefined,
            randomId: crypto.randomUUID(),
            mounted: false
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        stackedUsers: {
            type: Array,
            required: false,
            default: () => { return []; }
        },
        withName: {
            type: Boolean,
            required: false,
            default: false
        },
        displayNamePrefix: {
            type: String,
            required: false,
            default: ""
        },
        size: {
            type: Number,
            default: function () { return this.$CONSTANTS.AVATAR_SIZE_PX }
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    components: {
        'avatar': Avatar
    },
    mounted() {
        // console.log(el.getBoundingClientRect());
        /*const image = this.$getCookie(this.globalUserId);
        if (!image) {
            axios.get(`https://userapi.hybrooms.com/profilepicture?tid=${this.user.tid}&id=${this.user.id}`)
                .then(response => this.$setCookie(this.globalUserId, "data:image/webp;base64," + response.data, 0.5))
        }
        Vue.set(this.$store.state.profilePictures, this.globalUserId, this.$getCookie(this.globalUserId));*/
        if (!(this.globalUserId in this.$store.state.profilePictures)) {
            // Due to race condition during the request, both avatars in rooms and in the sidebar
            // make duplicate requests. To avoid that, we set the key. This can still have race conditions
            // Maybe the best way is to fetch the images at the beginning and not here in person component
            Vue.set(this.$store.state.profilePictures, this.globalUserId, "");
            hybRoomsConnector.getProfilePicture(this.user.tid, this.user.id).then(response => {
                if (response.headers["content-type"] == "application/xml")
                    throw new Error();
                Vue.set(this.$store.state.profilePictures, this.globalUserId, "data:image/webp;base64," + response.data)
            })
                .catch(() => Vue.set(this.$store.state.profilePictures, this.globalUserId, ""));
        }
        this.mounted = true;
    },
    methods: {
        getContactUrl(contactType, upn) {
            if (contactType == "chat")
                return `https://teams.microsoft.com/l/chat/0/0?users=${upn}`;
            else if (contactType == "audio")
                return `https://teams.microsoft.com/l/call/0/0?users=${upn}`
            else if (contactType == "video")
                return `https://teams.microsoft.com/l/call/0/0?users=${upn}&withVideo=true`;
            return undefined;
        },
        contactUser(contactType) {
            this.cardOpen = false;
            const loadingComponentId = this.$showLoading(this.$t("contactingUser"));
            const tid = this.user.tid || this.hoveredUser?.tid;
            const id = this.user.id || this.hoveredUser?.id;
            hybRoomsConnector.getUserPrincipalName(tid, id)
                .then(response => this.$openLink(this.getContactUrl(contactType, response.data.upn)))
                .catch(() => {
                    this.$buefy.toast.open({
                        duration: 2500,
                        message: this.$t("failedToRetrieveUser"),
                        type: "is-danger",
                        pauseOnHover: true
                    })
                })
                .finally(() => this.$closeLoading(loadingComponentId));
        }
    },
    computed: {
        isMobileOrTablet() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.desktop;
        },
        isMe() {
            return this.user.tid == this.$store.state.me.tid && this.user.id == this.$store.state.me.id;
        },
        image() {
            return this.$store.state.profilePictures[this.globalUserId] || "";
        },
        globalUserId() {
            return [this.user.tid, this.user.id].join(".");
        },
        cardTooltipActive() {
            return this.insideTooltip || this.insideAvatar;
        },
        displayNamePrefixOfMe() {
            return this.isMe ? "(" + this.$t("you") + ") " : "";
        },
        displayName() {
            return this.displayNamePrefixOfMe + this.user.displayName;
        },
        longestStackedUserName() {
            if (this.stackedUsers.length > 0) {
                const maxLength = Math.max(...this.stackedUsers.map(u => u.displayName.length));
                return Math.min(maxLength, 20);
            }
            return 0;
        },
        dropdownMaxWidthPx() {
            const pixelWidth = this.longestStackedUserName * 20;
            return Math.max(Math.min(this.windowWidth * 0.15, 200), pixelWidth);
        },
        directConnectIconsCardStyle() {
            if (this.isMobileOrTablet)
                return "width: calc(100% - 2rem);"
            return `max-width: ${this.dropdownMaxWidthPx}px;`
        },
        dropdownPosition() {
            if (!this.mounted)
                return undefined;
            const el = document.getElementById(this.randomId);
            if (el.getBoundingClientRect().x + this.dropdownMaxWidthPx > this.windowWidth)
                return "is-bottom-left";
            return "is-bottom-right"
        },
        windowWidth() {
            return this.$store.state.windowWidth;
        }
    }
};
</script>