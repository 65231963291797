<template>
    <span class="icon-text custom-hover-primary" v-on:click="openLanguageSelectionModal">
        <span class="icon">
            <i>
                <b-icon icon="globe" class="is-clickable" />
            </i>
        </span>
        <span>{{ $i18n.locale.split("-")[0].toUpperCase() }}</span>
    </span>
</template>

<script>
import LanguageSelectionModal from '@/components/LanguageSelectionModal.vue';

export default {
    methods: {
        openLanguageSelectionModal() {
            this.$buefy.modal.open({
                parent: this,
                component: LanguageSelectionModal,
                hasModalCard: false,
                trapFocus: true,
                fullScreen: false
            })
        }
    }
}
</script>