<template>
    <div :style="$store.state.calendarAllowed ? '' : 'opacity: 0.5;'">
        <!--b-collapse class="card" animation="slide" v-model="openCalendarSettings">
        <div class="card-content">
            <b-field>
                <h3 class="subtitle">{{ $t\('calendarSettings') }}</h3>
            </b-field>
            <b-field :label="$t\('cellSize')" horizontal>
                <b-slider v-model="cellSizeFactor" :min="1.0" :max="2.0" :step="0.25" style="width: 50%;" :tooltip="false">
                    <b-slider-tick :value="1.0">{{ $t\('sizes.verySmall') }}</b-slider-tick>
                    <b-slider-tick :value="1.25">{{ $t\('sizes.small') }}</b-slider-tick>
                    <b-slider-tick :value="1.5">{{ $t\('sizes.medium') }}</b-slider-tick>
                    <b-slider-tick :value="1.75">{{ $t\('sizes.large') }}</b-slider-tick>
                    <b-slider-tick :value="2.0">{{ $t\('sizes.veryLarge') }}</b-slider-tick>
                </b-slider>
            </b-field>
            <b-button type="is-primary" size="is-small" v-on:click="openCalendarSettings = !openCalendarSettings">{{ $t\('interaction.OK') }}</b-button>
        </div>
    </b-collapse-->
        <b-notification class="rounded-borders" :closable="false" :type="darkMode ? 'is-dark' : 'is-light'"
            style="margin-bottom: 0.75rem;">
            <div class="tile is-ancestor">
                <div class="tile is-parent">
                    <div class="tile is-child is-1">
                        <b-icon v-if="enablePreviousDay" class="is-clickable" pack="fas" icon="chevron-left"
                            v-on:click.native="previousDay" />
                    </div>
                    <div class="tile is-child">
                        <h5 class="title is-5 has-text-centered">{{ formattedDate }}</h5>
                    </div>
                    <div class="tile is-child is-1">
                        <b-icon v-if="enableNextDay" class="is-clickable" pack="fas" icon="chevron-right"
                            v-on:click.native="nextDay" />
                    </div>
                </div>
            </div>
        </b-notification>
        <vue-cal hide-view-selector hide-title-bar id="calendar" ref="calendar" class="rounded-borders"
            :editable-events="{ title: false, drag: false, resize: false, delete: false, create: false }"
            :active-view.sync="activeView" :disable-views="['week', 'month', 'year', 'years']" @ready="scrollToCurrentTime"
            :snap-to-time="snapToTime" @view-change="viewChanged($event)" :dblclickToNavigate="false"
            @cell-dblclick="onCellDoubleClick($event)" :on-event-dblclick="onEventDoubleClick"
            :on-event-click="onEventClick" :selected-date="selectedDate" :timeCellHeight="timeCellHeight" :time-step="15"
            :todayButton="false" :watchRealTime="true" default-view="week" :events-on-month-view="false"
            overlaps-per-time-step :events="events" style="height: 90vh;" :snapToTime="snapToTime"
            :special-hours="specialHours" :minEventWidth="0" :locale="$i18n.locale.slice(0, 2)"
            :hide-weekends="!showWeekend">
            <template v-slot:time-cell="{ hours, minutes }">
                <div :class="{ 'vuecal__time-cell-line': true, hours: !minutes, quarters: minutes % 10, halves: minutes == 30 }"
                    :id="`time-cell-line-${hours}-${minutes}`">
                    <p v-if="!minutes" style="font-size: 15px;">{{ String(hours).padStart(2, '0') }}:{{
                        String(minutes).padStart(2, '0') }}</p>
                    <!--span v-else style="font-size: 11px">{{ minutes }}</span-->
                </div>
            </template>
            <template v-slot:event="{ event }">
                <b-field grouped>
                    <div :class="`is-clickable is-flex vuecal__event-title_${event.class}`">
                        <div class="mr-1 is-flex is-flex-direction-column" :style="`font-size: ${cellTitleSize}rem;`">
                            {{ !event.allDay ? slotTimeText(event.start) : "" }}
                            <b-icon v-if="event.data.isOnline" size="is-small" icon="video"></b-icon>
                        </div>
                        <div>
                            <div :style="`font-size: ${cellTitleSize}rem;`">
                                {{ event.name }}
                            </div>
                            <div :style="`font-size: ${cellTitleSize * 0.8}rem;`">
                                {{ event.data.location }}
                            </div>
                            <!--span class="icon-text" v-if="event.data.location">
                        <span class="icon">
                            <i>
                                <b-icon icon="map-marker-alt" :style="`font-size: ${cellTitleSize * 0.8}rem;`" />
                            </i>
                        </span>
                    </span>
                    <span :style="`font-size: ${cellTitleSize * 0.8}rem;`">
                        {{ event.data.location.displayName }}
                    </span-->
                        </div>
                    </div>
                    <div v-if="isCurrentOnlineMeeting(event)" class="is-flex is-align-items-center">
                        <b-button v-on:click="joinMeeting(event.data.url)" size="is-small" type="is-primary" class="ml-1"
                            inverted>Join</b-button>
                    </div>
                </b-field>
            </template>
            <template v-slot:no-event>
                <span v-if="!$store.state.calendarAllowed">
                    {{ $t("sentences.adminConsentRequiredForCalendarAccess") }}
                </span>
                <span v-else />
            </template>
        </vue-cal>
        <b-loading :active="isLoading" :is-full-page="false" :can-cancel="true">
            <!-- This b-icon deletes the icon if calendar is not allowed and adds an overlay to the calendar -->
            <b-icon v-if="!$store.state.calendarAllowed"></b-icon>
        </b-loading>
    </div>
</template>

<script>
import vuecal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import axios from "axios";

import {
    sameDay
} from '@/lib/dateUtils';

import ViewAppointmentDetails from "@/components/ViewAppointmentDetails.vue";

export default {
    components: {
        'vue-cal': vuecal,
    },
    async mounted() {
        document.documentElement.style.setProperty("--dayOfWeekForNowLine", this.dayInCalendar);
        document.documentElement.style.setProperty("--nowLineWidthMultiplier", 7);
        this.scrollToCurrentTime();
        this.updateNow();
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        startOfSelectedDate() {
            let startOfSelectedDate = new Date(this.selectedDate);
            startOfSelectedDate.setHours(0, 0, 0, 0);
            return startOfSelectedDate;
        },

        events() {
            return this.$store.state.myEvents[this.startOfSelectedDate.toISOString()];
        },

        formattedDate() {
            const dateFormatOptions = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: undefined,
                minute: undefined
            };
            const date = this.$store.state.demoMode.active && this.$store.state.demoMode.dateTimeOverride.active ? this.$store.state.demoMode.dateTimeOverride.value : this.selectedDate;
            return date.toLocaleDateString(this.$i18n.locale, dateFormatOptions);
        },

        enableNextDay() {
            const timeOk = (this.now.getHours() >= this.$CONSTANTS.ENABLE_NEXT_DAY_AFTER && this.selectedDate.getDate() == this.now.getDate()) || this.selectedDate.getDate() < this.now.getDate();
            return timeOk && this.$store.state.calendarAllowed;
        },

        enablePreviousDay() {
            const timeOk = (this.now.getHours() < this.$CONSTANTS.ENABLE_PREVIOUS_DAY_BEFORE && this.selectedDate.getDate() == this.now.getDate()) || this.selectedDate.getDate() > this.now.getDate();
            return timeOk && this.$store.state.calendarAllowed;
        },

        appointmentTypes() {
            let map = {};
            /*for (let a of this.$store.state.appointmentTypes) {
                map[a.id] = a;
            }*/
            return map;
        },

        businessData() {
            return this.$store.state.businessData;
        },

        isMobile() {
            const isMobile = this.$store.getters.deviceType === 'mobile';
            if (isMobile && this.activeView == 'week' && this.$refs.calendar)
                this.$refs.calendar.switchToNarrowerView();
            return isMobile;
        },

        darkMode() {
            return this.$store.state.darkMode;
        },

        numDaysInWeekView() {
            return this.showWeekend ? 7 : 5;
        },

        dayInCalendar() {
            let dayInCalendar = new Date().getDay() - 1;
            if (dayInCalendar < 0)
                dayInCalendar += 7;
            return dayInCalendar;
        },

        snapToTime() {
            return 5;
        },

        remInPixels() {
            return parseInt(getComputedStyle(document.documentElement).fontSize);
        },

        cellSizeFactor: {
            get() {
                return this.$store.state.calendarCellSizeFactor;
            },
            set(value) {
                this.$store.state.calendarCellSizeFactor = value;
            }
        },

        cellTitleSize() {
            return Math.max(0.7, Math.min(this.cellSizeFactor * 0.5, 0.9));
        },

        timeCellHeight() {
            return this.cellSizeFactor * this.remInPixels;
        }
    },
    methods: {
        isCurrentOnlineMeeting(event) {
            return event.data.isOnline && this.now >= event.start.getTime() - 5 * 60000 && this.now <= event.end.getTime();
        },
        updateNow() {
            setTimeout(() => {
                this.now = new Date();
                this.updateNow()
            }, 1000)
        },
        onCellDoubleClick(clickedDate) {
            if (this.activeView === 'year' || this.activeView === 'month') {
                this.$refs.calendar.switchToNarrowerView(); //switchView('month', new Date(event.getTime()))
                return;
            }
            const duration = 30;
            clickedDate.setMinutes(Math.round(clickedDate.getMinutes() / this.snapToTime) * this.snapToTime);
            const appointment = this.createEvent(clickedDate, new Date(clickedDate.getTime() + duration * 60000));
            this.addNewAppointment(appointment);
        },

        joinMeeting(url) {
            this.$emit("joinMeetingFromCalendar", url);
        },

        nextDay() {
            if (this.enableNextDay) {
                this.selectedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate() + 1);
                this.$emit("fetchEventsWithDayOffset", this.selectedDate.getDate() - this.now.getDate());
            }
        },

        previousDay() {
            if (this.enablePreviousDay) {
                this.selectedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate() - 1);
                this.$emit("fetchEventsWithDayOffset", this.selectedDate.getDate() - this.now.getDate());
            }
        },

        onEventClick(event, _) {
            console.log(event);
        },

        onEventDoubleClick(event, e) {
            this.$buefy.modal.open({
                parent: this,
                component: ViewAppointmentDetails,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                props: {
                    id: event.id,
                    event: event
                },
                events: {
                    'joinMeetingFromEventModal': (url) => {
                        this.joinMeeting(url);
                    }
                }
            });
        },

        updateAppointment(event) {
            const idx = this.events.findIndex(item => item.id === event.id);
            if (idx != -1) {
                this.events.splice(idx, 1);
                this.events.push(this.parseEvent(event.data));
            }
        },

        scrollToCurrentTime() {
            if (this.activeView !== 'day' && this.activeView !== 'week')
                return;
            const calendar = document.querySelector('#calendar .vuecal__bg');
            const now = new Date();
            let hours = now.getHours() + now.getMinutes() / 60 - 1;
            if (this.activeView == 'day' && !sameDay(now, this.$refs.calendar.view.startDate))
                hours = 7.5;
            else if (this.activeView == 'week' && (this.$refs.calendar.view.startDate > now || this.$refs.calendar.view.endDate < now)) {
                hours = 7.5;
            }
            calendar.scrollTo({
                top: hours * this.timeCellHeight * 4,
                behavior: 'smooth'
            })
        },

        getEventColor(raw_event) {
            return this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].color : 'blue';
        },

        createEmptyAppointmentDetails(start = null, end = null) {
            return {
                duration: start && end ? (end - start) / 60000 : null,
                format: null,
                providerId: this.businessData.length == 1 ? this.getRelativeLocationId(this.businessData[0]) : null,
                salutation: null,
                firstName: null,
                lastName: null,
                email: null,
                tel: null,
                message: "",
                type: null,
                birthDate: {
                    day: null,
                    month: null,
                    year: null
                },
                insuranceType: null,
            }
        },

        createEvent(start = null, end = null, salutation = null, name = null, data = null, cls = "primary") {
            let d = this.createEmptyAppointmentDetails(start, end);
            if (data)
                d = {
                    ...d,
                    ...data
                }

            return {
                id: crypto.randomUUID(),
                start: start,
                end: end,
                salutation: salutation,
                name: name,
                //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
                data: d,
                class: cls
            }
        },

        parseEvent(raw_event) {
            const date_str = '20' + raw_event.slot.slice(0, 2) + '-' + raw_event.slot.slice(2, 4) + '-' + raw_event.slot.slice(4, 6) + 'T' + raw_event.slot.slice(6, 8) + ':' + raw_event.slot.slice(8, 10);
            const providerId = raw_event.slot.slice(10, 10 + this.$CONSTANTS.RELATIVE_PROVIDER_ID_LENGTH);
            // const extended_id = raw_event.slot.slice(10 + this.$CONSTANTS.RELATIVE_PROVIDER_ID_LENGTH);
            const start = new Date(date_str)
            const end = new Date(start.getTime() + raw_event.duration * 60000)
            if (typeof (raw_event.birthDate) === 'number') {
                let birthDate = new Date(0);
                birthDate.setUTCSeconds(raw_event.birthDate * 86400);
                raw_event.birthDate = {
                    day: birthDate.getUTCDate(),
                    month: birthDate.getUTCMonth() + 1,
                    year: birthDate.getUTCFullYear()
                };
            }
            raw_event.format = raw_event.format ? true : false;
            raw_event.providerId = providerId;
            // raw_event.extended_id = extended_id;
            return this.createEvent(start, end, raw_event.salutation, raw_event.lastName + ', ' + raw_event.firstName, raw_event, this.getEventColor(raw_event))
        },

        parseEvents(data) {
            this.events = []
            for (var raw_event of data) {
                this.events.push(this.parseEvent(raw_event))
                /*this.events.push({
                    start: start,
                    end: end,
                    salutation: raw_event.salutation,
                    name: raw_event.lastName + ', ' + raw_event.firstName,
                    //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
                    data: raw_event,
                    class: this.getEventColor(raw_event)
                })
                this.events.push({
                    start: start,
                    end: end,
                    salutation: raw_event.salutation,
                    name: raw_event.lastName + ', ' + raw_event.firstName,
                    //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
                    data: raw_event,
                    class: 'brown'
                })
                this.events.push({
                    start: start,
                    end: end,
                    salutation: raw_event.salutation,
                    name: raw_event.lastName + ', ' + raw_event.firstName,
                    //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
                    data: raw_event,
                    class: 'cyan'
                })*/
            }
        },
        async loadTime(token, startDate) {
            const cfg = {
                headers: {
                    'authorization': token,
                    "content-type": "application/json"
                }
            }
            const options = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            var parts = Intl.DateTimeFormat('de', options).formatToParts(this.$refs.calendar.view.startDate);
            const start = parts[4].value + parts[2].value + parts[0].value + "0000";
            /*var endDate = new Date(startDate.getTime() + 24 * 3600 * 1000 * 7);
            parts = Intl.DateTimeFormat('de', options).formatToParts(endDate);
            const end = parts[4].value + parts[2].value + parts[0].value;*/
            var parts2 = Intl.DateTimeFormat('de', options).formatToParts(this.$refs.calendar.view.endDate);
            const end = parts2[4].value + parts2[2].value + parts2[0].value + "2359";
            /*return axios
                .get(get_query_appointments_url(start, end), cfg)
                .then(response => this.parseEvents(response.data))*/
        },
        viewChanged(event) {
            /*if (event.view === "day" || event.view === "week") {
                Auth.currentAuthenticatedUser({
                    bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                }).then(user => this.loadTime(user["signInUserSession"]["idToken"]["jwtToken"]), new Date())
            }*/

            var dayOfWeekForNowLine = 0;
            var nowLineWidthMultiplier = 1;
            if (event.view === "week") {
                dayOfWeekForNowLine = this.dayInCalendar;
                nowLineWidthMultiplier = 7;
            }
            document.documentElement.style.setProperty("--dayOfWeekForNowLine", dayOfWeekForNowLine);
            document.documentElement.style.setProperty("--nowLineWidthMultiplier", nowLineWidthMultiplier);
            this.$nextTick(() => {
                this.scrollToCurrentTime();
            });
        },
        slotTimeText(start) {
            const hour = ("0" + start.getHours()).slice(-2);
            const minute = ("0" + start.getMinutes()).slice(-2);
            return hour + ':' + minute;
        }
    },
    data: () => ({
        now: new Date(),
        openCalendarSettings: false,
        activeView: 'day',
        selectedDate: new Date(),
        shiftNowLine: true,
        //splits: [{ label: 'John', class: 'john' }, { label: 'Kate', class: 'kate' }],
        specialHours: {
            /*3: {
                from: 8 * 60,
                to: 20 * 60,
                class: 'open'
            },
            6: {
                from: 0,
                to: 24 * 60,
                class: 'closed',
                label: 'Not available without admin consent'
            }*/
        },
        showWeekend: new Date().getDay() == 6 || new Date().getDay() == 0
        /*events: [{
            id: crypto.randomUUID(),
            start: new Date(2022, 10, 7, 9),
            end: new Date(2022, 10, 7, 10),
            salutation: "1",
            name: "Daily",
            //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
            data: null,
            class: "indigo"
        },
        {
            id: crypto.randomUUID(),
            start: new Date(2022, 10, 7, 10, 15),
            end: new Date(2022, 10, 7, 11),
            salutation: "1",
            name: "Sync for Hybrooms",
            //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
            data: null,
            class: "orange"
        },
        {
            id: crypto.randomUUID(),
            start: new Date(2022, 10, 7, 11, 30),
            end: new Date(2022, 10, 7, 12, 30),
            salutation: "1",
            name: "Lunch",
            //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
            data: null,
            class: "lime"
        },
        {
            id: crypto.randomUUID(),
            start: new Date(2022, 10, 7, 14),
            end: new Date(2022, 10, 7, 15),
            salutation: "1",
            name: "After Lunch",
            //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
            data: null,
            class: "cyan"
        },
        {
            id: crypto.randomUUID(),
            start: new Date(2022, 10, 7, 15, 30),
            end: new Date(2022, 10, 7, 16),
            salutation: "1",
            name: "Testfahrt",
            //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
            data: null,
            class: "indigo"
        },
        {
            id: crypto.randomUUID(),
            start: new Date(2022, 10, 7, 16),
            end: new Date(2022, 10, 7, 17),
            salutation: "1",
            name: "Abschluss",
            //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
            data: null,
            class: "indigo"
        },
        ]*/
        /*[{
                start: "2021-09-19 18:30",
                end: "2021-09-19 19:15",
                title: "Dentist appointment",
                content: '<i class="v-icon material-icons">local_hospital</i>',
                class: "health"
            },
            {
              start: "2021-09-21",
              end: "2021-09-21",
              title: "Need to go shopping",
              content: '<i class="v-icon material-icons">shopping_cart</i>',
              class: "leisure"
            },
            {
                start: new Date(),
                end: "2021-09-25 14:30",
                title: "Doctor appointment",
                class: "health"
            },
            {
                start: "2021-09-24 10:35",
                end: "2021-09-19 11:30",
                title: "Doctor appointment",
                class: "health"
            },
            {
                start: "2021-09-19 18:30",
                end: "2021-09-19 19:15",
                title: "Eating appointment",
                class: "leisure"
            },
            {
                start: "2021-09-20 18:30",
                end: "2021-09-20 20:30",
                title: "Crossfit"
            },
            {
                start: "2021-09-21 11:00",
                end: "2021-09-21 13:00",
                title: "Brunch with Jane",
                class: "health",
                split: 1
            },
            {
                start: "2021-09-21 19:30",
                end: "2021-09-21 23:00",
                title: "Swimming lesson"
            },
            {
                start: "2019-09-30 19:30",
                end: "2019-09-30 23:00",
                title: "Swimming lesson"
            },
            {
                start: "2021-09-19 12:00",
                end: "2021-09-19 14:00",
                title: "LUNCH",
                class: "lunch",
                background: true
            },
            {
                start: "2021-09-20 12:00",
                end: "2021-09-20 14:00",
                title: "LUNCH",
                class: "lunch",
                background: true
            }
        ]*/
    })
};
</script>

<style lang="scss">
/*     @import '../../node-modules/vue-cal/dist/vuecal.css'; */
/* @import "vue-cal/dist/vuecal.css"; */
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../assets/generated/colors.scss";

.vuecal__time-column .vuecal__time-cell {
    text-align: center;
}

.vuecal__now-line {
    color: $primary;
    width: calc(var(--nowLineWidthMultiplier) * 100% + 3rem);
    left: -3rem;
    // width: calc(var(--nowLineWidthMultiplier) * 100% + 0.8em);
    // left: calc(-1 * var(--dayOfWeekForNowLine) * 100% - 0.8em); //calc(-var(--dayOfWeek) * 100% - 0.8em);
    border-width: 2px;
    // opacity: 1;
}

/*.vuecal__now-line:before {
    left: 0em;
}*/

.vuecal__time-cell-line.hours:before {
    border-width: 1px;
    border-color: $grey-light
}

.vuecal__time-cell-line.halves:before {
    left: 3em;
}

.vuecal__time-column .vuecal__time-cell-line#time-cell-line-0-0:before {
    border-width: 0px;
}

.eventTime {
    color: $primary-invert;
    //font-size: 0.7em;
    font-size: 14px;
    margin-left: 0.35em;
}

$john: $primary;

// John.
.vuecal__header .john {
    color: darken($john, 5);
}

.vuecal__body .john {
    background-color: rgba($john, 0.08);
}

.john .vuecal__event {
    background-color: rgba(lighten($john, 5), 0.85);
    color: #fff;
}

.john .lunch {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba($john, 0.15) 10px, rgba($john, 0.15) 20px);
    color: transparentize(darken($john, 10), 0.4);
}

.vuecal--rounded-theme .vuecal__weekdays-headings {
    border-bottom: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.4);
}

.vuecal--rounded-theme .vuecal__cell,
.vuecal--rounded-theme .vuecal__cell:before {
    background: none;
    border: none
}

.vuecal--rounded-theme .vuecal__cell--out-of-scope {
    opacity: .4
}

.vuecal--rounded-theme .vuecal__cell-content {
    width: 100%;
    height: 30px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    border: 0px solid transparent;
    border-radius: 30px;
    color: #333;
    text-align: left;
}

.vuecal--rounded-theme.vuecal--day-view .vuecal__cell-content {
    width: auto;
    background: none;
}

.vuecal--rounded-theme.vuecal--year-view .vuecal__cell {
    width: 33.33%
}

.vuecal--rounded-theme.vuecal--year-view .vuecal__cell-content {
    width: 85px
}

.vuecal--rounded-theme.vuecal--years-view .vuecal__cell-content {
    width: 52px
}

.vuecal--rounded-theme .vuecal__cell {
    background-color: transparent !important
}

.vuecal--rounded-theme.vuecal--green-theme:not(.vuecal--day-view) .vuecal__cell-content {
    background-color: #f1faf7
}

.vuecal--rounded-theme.vuecal--green-theme:not(.vuecal--day-view) .vuecal__cell--today .vuecal__cell-content {
    background-color: #42b983;
    color: #fff
}

.vuecal--rounded-theme.vuecal--green-theme .vuecal--day-view .vuecal__cell--today:before {
    background-color: rgba(66, 185, 131, .05)
}

.vuecal--rounded-theme.vuecal--green-theme:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
    border-color: #42b983
}

.vuecal--rounded-theme.vuecal--green-theme .vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal--rounded-theme.vuecal--green-theme .vuecal__cell-split--highlighted {
    background-color: rgba(195, 255, 225, .5)
}

.vuecal--rounded-theme.vuecal--blue-theme:not(.vuecal--day-view) .vuecal__cell-content {
    background-color: rgba(100, 182, 255, .2)
}

.vuecal--rounded-theme.vuecal--blue-theme:not(.vuecal--day-view) .vuecal__cell--today .vuecal__cell-content {
    background-color: #8fb7e4;
    color: #fff
}

.vuecal--rounded-theme.vuecal--blue-theme .vuecal--day-view .vuecal__cell--today:before {
    background-color: rgba(143, 183, 228, .1)
}

.vuecal--rounded-theme.vuecal--blue-theme:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
    border-color: #61a9e0
}

.vuecal--rounded-theme.vuecal--blue-theme .vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal--rounded-theme.vuecal--blue-theme .vuecal__cell-split--highlighted {
    background-color: rgba(0, 165, 188, .06)
}

.vuecal--month-view .vuecal__cell {
    height: 80px;
}

.vuecal--month-view .vuecal__cell-content {
    justify-content: flex-start;
    height: 100%;
    align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
    padding: 4px;
}

.vuecal .vuecal__no-event {
    position: sticky;
    color: #000000;
    opacity: 0.5;
    top: 30%;
}

$colors: (
    "primary": ($primary, $primary-light),
    "red": ($red, $red-light),
    "pink": ($pink, $pink-light),
    "purple": ($purple, $purple-light),
    "deep_purple": ($deep_purple, $deep_purple-light),
    "indigo": ($indigo, $indigo-light),
    "blue": ($blue, $blue-light),
    "light_blue": ($light_blue, $light_blue-light),
    "cyan": ($cyan, $cyan-light),
    "teal": ($teal, $teal-light),
    "green": ($green, $green-light),
    "light_green": ($light_green, $light_green-light),
    "lime": ($lime, $lime-light),
    "yellow": ($yellow, $yellow-light),
    "amber": ($amber, $amber-light),
    "orange": ($orange, $orange-light),
    "deep_orange": ($deep_orange, $deep_orange-light),
    "grey": ($grey, $grey-light),
    "brown": ($brown, $brown-light),
    "blue_grey": ($blue_grey, $blue_grey-light),
    "teams": (#c7cbf8, findLightColor(#c7cbf8)),
    "teams_blue": (#b0d3f1, findLightColor(#b0d3f1)),
);

.vuecal__menu,
.vuecal__cell-events-count {
    background-color: $primary !important;
}

.vuecal__cell--selected {
    background-color: transparent !important;
}

.vuecal:not(.vuecal--day-view) .vuecal__cell--selected:before {
    background-color: rgba($primary, 0.1) !important;
    border-left: 1px solid $primary !important;
    border-right: 1px solid $primary !important;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
}

.vuecal.vuecal--week-view .vuecal__cell--selected:before {
    //background-color: rgba(white, 0.1) !important;
    border-top: none !important;
    border-top: none !important;
}

.vuecal.vuecal--day-view .vuecal__cell--selected:before {
    border: none !important;
}

.vuecal.vuecal--month-view .vuecal__cell--selected:before {
    background-color: rgba($primary, 0.1) !important;
    border-top: 1px solid $primary !important;
    border-bottom: 1px solid $primary !important;
}

.vuecal__cell--today,
.vuecal__cell--current {
    background-color: transparent !important;
}

// Default with primary color, specializations are in the for loops below
.vuecal__event {
    background-color: $primary;
    border: 1px solid $primary-light;
    border-radius: 5px;
}

.vuecal__event-title_ {
    color: findColorInvert($primary);
    margin: 0px 0 0px;
    margin-left: 0.25em;
    text-align: left;
}

@each $name,
$color in $colors {

    .vuecal__event--focus,
    .vuecal__event.#{$name}:focus {
        -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, .2) !important;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, .2) !important;
        z-index: 3 !important;
        outline: none !important;
        border-color: invert(nth($color, 2)) !important;
    }
}

@each $name,
$color in $colors {
    .vuecal__event.#{$name} {
        background-color: nth($color, 1);
        border: 1px solid nth($color, 2);
        border-radius: 5px;
    }
}

@each $name,
$color in $colors {
    .vuecal__event-title_#{$name} {
        color: findColorInvert(nth($color, 1));
        margin: 0px 0 0px;
        margin-left: 0.25em;
        text-align: left;
    }
}

.vuecal__special-hours {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;

    em {
        font-size: 0.9em;
        color: #999;
    }
}

.closed {
    background:
        $scheme-main repeating-linear-gradient(-45deg,
            rgba(255, 162, 87, 0.25),
            rgba(255, 162, 87, 0.25) 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px);
    color: $scheme-invert;
}
</style>