<template>
    <section class="hero is-fullheight loading">
        <div class="hero-body is-flex is-justify-content-center is-align-items-center container">
            <div class="has-text-centered">
                <slot name="before"></slot>
                <p v-if="title" class="title is-flex is-justify-content-center mb-6">{{ title }}</p>
                <p v-if="subtitle && subtitleDelayPast" class="subtitle is-flex is-justify-content-center">{{
                    subtitle
                }}</p>
                <div v-if="showDots" class="is-flex is-justify-content-center">
                    <loading-dots v-if="showDots" class="mb-6" />
                </div>
                <div>
                    <slot name="after"></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LoadingDots from "@/components/LoadingDots.vue";

export default {
    props: {
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
        subtitleDelay: {
            type: Number,
            default: 0
        },
        showDots: {
            type: Boolean,
            default: true
        }
    },
    components: {
        "loading-dots": LoadingDots
    },
    data() {
        return {
            isLoading: true,
            subtitleDelayPast: false
        }
    },
    mounted() {
        setTimeout(() => { this.subtitleDelayPast = true }, this.subtitleDelay * 1000);
    }
}
</script>

<style>
.loading {
    width: 100vw;
    position: fixed;
    left: 0px;
    top: 0px;
}
</style>