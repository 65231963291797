<template>
    <b-navbar ref="navbar" :fixed-top="true" :centered="!isOfficeSpaceView" :transparent="true">
        <template #brand>
            <div :style="navbarMarginLeftStyle" />
            <b-navbar-item tag="router-link" :to="{ path: brandPath }">
                <img src="@/assets/hr_logo96.png" alt="Make home to office">
                <div v-if="isAnalyticsView" class="title is-5 ml-1">analytics</div>
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item v-if="isOfficeSpaceView">
                <create-dropdown v-on:createOfficeSpace="createOfficeSpace" v-on:createRoom="createRoom"></create-dropdown>
            </b-navbar-item>
            <b-navbar-item v-if="isOfficeSpaceView">
                <breadcrumb />
            </b-navbar-item>
            <b-navbar-item v-if="!isOfficeSpaceView" tag="router-link" :to="{ path: 'init' }">{{
                $t("experienceNow")
            }}</b-navbar-item>
            <b-navbar-item v-if="!isOfficeSpaceView" tag="router-link" :to="{ path: 'pricing' }">{{
                $t("pricing.pricing")
            }}</b-navbar-item>
            <b-navbar-item v-if="!isOfficeSpaceView" tag="router-link" :to="{ path: 'about' }">{{ $t("aboutUs")
            }}</b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item v-if="isOfficeSpaceView && defaultOfficeSpace.tid && !isDefaultOfficeSpace">
                <span class="icon-text custom-hover-primary"
                    v-on:click="$emit('navigateToOfficeSpace', defaultOfficeSpace)">
                    <span class="icon">
                        <i>
                            <b-icon icon="home" class="is-clickable" />
                        </i>
                    </span>
                </span>
            </b-navbar-item>
            <b-navbar-item v-if="isOfficeSpaceView">
                <b-autocomplete :placeholder="$t('placeholders.goToOfficeSpace')" field="name"
                    v-model="officeSpaceSearchInput" @select="officeSpaceSelected" :data="filteredOfficeSpaces"
                    :loading="fetchingOfficeSpaces" icon="walking" :clearable="true" autocomplete="nope"
                    :clear-on-select="false">
                    <template slot-scope="props">
                        <div class="custom-hover-primary">
                            {{ props.option.name }}
                        </div>
                    </template>
                </b-autocomplete>
            </b-navbar-item>
            <b-navbar-dropdown v-if="isOfficeSpaceView && !isMobile" :right="true" arrowless>
                <template #label>
                    <b-icon icon="cog" size="is-medium"></b-icon>
                </template>
                <b-navbar-item>
                    <language-selector />
                </b-navbar-item>
                <b-navbar-item>
                    <b-switch v-model="isDarkMode" v-on:input="setDarkModeWithCookie">Dark mode</b-switch>
                </b-navbar-item>
            </b-navbar-dropdown>
            <div v-if="isOfficeSpaceView && isMobile">
                <b-navbar-item>
                    <language-selector />
                </b-navbar-item>
                <b-navbar-item>
                    <b-switch v-model="isDarkMode" v-on:input="setDarkModeWithCookie">Dark mode</b-switch>
                </b-navbar-item>
            </div>
            <b-navbar-item v-if="!isOfficeSpaceView">
                <language-selector />
            </b-navbar-item>
            <div :style="navbarMarginRightStyle" />
        </template>
    </b-navbar>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import CreateDropdown from "@/components/CreateDropdown.vue";

export default {
    components: {
        "breadcrumb": Breadcrumb,
        "create-dropdown": CreateDropdown,
        "language-selector": LanguageSelector
    },

    data() {
        return {
            officeSpaceSearchInput: ""
        }
    },

    computed: {
        brandPath() {
            if (!this.$store.state.me?.tid || (this.$store.state.hybRoomsClient != this.$CONSTANTS.MS_TEAMS_CLIENT_NAME && this.$store.state.hybRoomsClient != this.$CONSTANTS.ZOOM_CLIENT_NAME))
                return "/";
            // if (this.defaultOfficeSpace)
            //     return `/office/${this.defaultOfficeSpace.tid}/${this.defaultOfficeSpace.id}`;
            return `/office/${this.$store.state.me.tid}`;
        },

        isDarkMode: {
            get() {
                return this.$store.state.darkMode;
            },
            set(value) {
                return;
            }
        },

        isMobile() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.tablet;
        },

        isOfficeSpaceView() {
            return this.$route.name == "officeSpace";
        },

        isAnalyticsView() {
            return this.$route.name == "analytics";
        },

        officeSpaces() {
            return this.$store.state.officeSpaces;
        },

        filteredOfficeSpaces() {
            return this.officeSpaces.filter(o => {
                return o.name ? (o.id != this.viewedOfficeSpaceId || o.tid != this.viewTenantId) && o.name.toLowerCase().indexOf(this.officeSpaceSearchInput.toLowerCase()) >= 0 : false
            });
        },

        fetchingOfficeSpaces() {
            return this.$store.state.fetchingOfficeSpaces;
        },

        viewTenantId() {
            return this.$store.state.viewTenantId;
        },

        viewedOfficeSpaceId() {
            return this.$store.state.viewedOfficeSpaceId;
        },

        currentOfficeSpace() {
            return this.officeSpaces.find(o => o.id == this.viewedOfficeSpaceId && o.tid == this.viewTenantId);
        },

        defaultOfficeSpace() {
            return this.$store.state.defaultOfficeSpace;
        },

        isDefaultOfficeSpace() {
            if (this.defaultOfficeSpace && this.currentOfficeSpace && this.currentOfficeSpace.tid == this.defaultOfficeSpace.tid && this.currentOfficeSpace.id == this.defaultOfficeSpace.id)
                return true;
            return false;
        },

        isTablet() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.desktop;
        },

        navbarMarginLeftStyle() {
            const margin = this.isOfficeSpaceView ? 0 : Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-left: ${margin}px;`
        },

        navbarMarginRightStyle() {
            const margin = this.isOfficeSpaceView ? 0 : Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-right: ${margin}px;`
        },

        locationHierarchy() {
            let hierarchy = [{
                name: "HybRooms",
                link: encodeURI(`/office/${this.viewTenantId}`)
            }]
            if (this.currentOfficeSpace)
                hierarchy.push({
                    name: this.currentOfficeSpace.name,
                    link: encodeURI(`/office/${this.viewTenantId}/${this.currentOfficeSpace.id}`),
                    isDefaultOfficeSpace: this.isDefaultOfficeSpace
                })
            return hierarchy;
        },

        /*navbarStartStyle() {
            if (this.isOfficeSpaceView)
                return ""
            const brandElement = document.getElementsByClassName("navbar-brand")[0];
            const endElement = document.getElementsByClassName("navbar-end")[0];
            if (!brandElement || !endElement)
                return ""
            const brandWidth = brandElement.getBoundingClientRect().width;
            const endWidth = endElement.getBoundingClientRect().width;
            return `margin-left: ${(endWidth - brandWidth) * 0.5}px;`
        }*/
    },

    methods: {
        officeSpaceSelected(option) {
            // When the autucomplete is cleared, it is called with an empty option.
            // This early exit is a workaround for that
            if (!option)
                return;
            this.$emit("navigateToOfficeSpace", option);
        },

        createOfficeSpace(event) {
            this.$emit("createOfficeSpace", event);
        },

        createRoom(event) {
            this.$emit("createRoom", event);
        },

        setDarkModeWithCookie(value) {
            if (this.$store.state.systemDarkMode === value)
                this.$deleteCookie("darkMode");
            else
                this.$setCookie("darkMode", value, 365);
            this.$setDarkMode(value);
        },
    }
}
</script>

<style scoped>
.field.routerItemWithPin .field:not(:last-child) {
    margin-right: 0.4rem;
}
</style>
