export async function copyToClipboard(text) {
    try{
        await navigator.clipboard.writeText(text);
        return true;
    }
    catch{
        return fallbackCopyTextToClipboard(text);
    }
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "-9999px";
    textArea.style.position = "absolute";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const success = document.execCommand('copy');
      document.body.removeChild(textArea);
      return success;
    } catch (err) {
        document.body.removeChild(textArea);
        return false;
    }
  }