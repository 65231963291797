<template>
    <b-dropdown aria-role="list">
        <template #trigger="{ active }">
            <b-button icon-left="plus" :triggers="['hover']" outlined rounded
                :icon-right="active ? 'chevron-up' : 'chevron-down'">{{ $t('create') }}</b-button>
        </template>
        <b-dropdown-item aria-role="listitem" v-on:click="$emit('createOfficeSpace')">
            <b-field grouped class="custom-hover-primary">
                <b-field>
                    <b-icon pack="fas" icon="vector-square" size="is-small" />
                </b-field>
                <b-field>
                    {{ $t("officeSpace") }}
                </b-field>
            </b-field>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" v-on:click="$emit('createRoom')" :disabled="!viewedOfficeSpaceId">
            <b-field grouped class="custom-hover-primary">
                <b-field>
                    <b-icon pack="fas" icon="door-open" size="is-small" />
                </b-field>
                <b-field>
                    {{ $t("room") }}
                </b-field>
            </b-field>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    computed: {
        viewedOfficeSpaceId() {
            return this.$store.state.viewedOfficeSpaceId;
        },
    }
}
</script>